@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Poppins, sans-serif;
}
.topic-details img {
  display: block;
  max-width: 100%;
  max-height: 400px;
  width: auto;
  height: auto;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

/* Ensure the HtmlRenderer content doesn't overflow */
.topic-details {
  max-width: 100%;
  overflow-x: auto;
  word-wrap: break-word;
}

/* Table-specific styles */
.topic-details table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  overflow-x: auto;
}

.topic-details th,
.topic-details td {
  padding: 0.5rem;
  border: 1px solid #ddd;
  text-align: left;
}

/* Add scroll behavior if content overflows */
.topic-details::-webkit-scrollbar {
  height: 6px;
  background-color: #f1f1f1;
}

.topic-details::-webkit-scrollbar-thumb {
  background-color: #888;
}

.topic-details::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.blog-content img {
  max-width: 100%;
  display: block;
  margin: 15px ; /* Ensure spacing is the same as in the editor */
  border-radius: 5px;
}
.topic-box {
  padding: 12px 15px;
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  transition: background 0.3s ease-in-out;
  min-height: 40px; /* Ensures all topic boxes have the same height */
  display: flex;
  align-items: center; /* Vertically center text */
}

.topic-box:hover {
  background: #f0f0f0;
}

.nested-subjects  {
  padding: 10px;
  background: #04aa5033;
  /* color: #04aa50; */
}
.nested-subjects ul li {
  /* background: #04aa5033; */
  font-size: 16px;
  font-weight: 400;
  /* text-align: center; */
  color: #04aa50;
  /* margin: 10px; */
  /* border-left: 3px solid #009580; */
  /* list-style-type: none; */
  padding: 5px;
  cursor: pointer;
  /* border-radius: 0 5px 5px 0; */
}
.chevron-icon {
  margin-left: 8px;
  font-size: 14px;
  transition: transform 0.2s;
}

.nested-topics .topic-item {
  font-weight: normal;
}

.subject-item,
.topic-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.2s, color 0.2s;
  /* border-bottom: 1px solid #ddd; */
  /* position: relative; */
}

.subject-item:hover,
.topic-item:hover {
  /* background: #f5f5f5; */
  color: #333;
}

.nested-topics {
  /* padding-left: 20px; */
  display: block;
}

.topic-name,
.subject-name {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subject-list{
  list-style-type: none;
  padding: 0;
}